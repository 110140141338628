<template>
	<w-flex grow>
		<w-text-input v-model="search" clearable :label="$t('actions.search')" prepend-icon="search" single-line />
	</w-flex>
</template>

<script>
import ProjectsManagerModuleGuard from '@/mixins/ModulesGuards/Offers/ProjectsManagerModuleGuard'

export default {
	name: 'CustomersManagerOffersSearchBar',
	mixins: [ProjectsManagerModuleGuard],
	computed: {
		search: {
			get: function () {
				return this.$store.state.customers.projects.filters.search
			},
			set: function (val) {
				this.$store.dispatch('customers/projects/setSearchedText', val)
			}
		}
	}
}
</script>
